import { useEffect, useState } from 'react'

import { CssBaseline, StyledEngineProvider } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ThemeProvider } from '@mui/material/styles'
import { Auth } from 'aws-amplify'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'

// project imports
import NavigationScroll from './layout/NavigationScroll'
// routing
import Routes from './routes'
import { getSmartClimateDevices } from './services/smartClimateDeviceServices'
//QUERY TO GET USER DATA
import {
    getEnergyAllDevices,
    getEnergyPerDevice,
    getTimesUsedPerDevice,
    getVervConnects,
} from './services/vervDeviceServices'
import {
    ERROR,
    isLoading,
    LOADING,
    SAVED,
    SET_AUTHED,
    SET_DEVICE_SERIALS,
    SET_ENERGY_ALL_DEVICES,
    SET_ENERGY_PER_DEVICE,
    SET_SMART_CLIMATE_DEVICES,
    SET_TIMES_USED_PER_DEVICE,
    SET_USER_DEVICES,
    setSelectedSerial,
} from './store/actions'
// defaultTheme
import themes from './themes'

import 'react-toastify/dist/ReactToastify.css'
import '@aws-amplify/ui-react/styles.css'

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization)
    const dispatch = useDispatch()

    // FIND IF USER IS LOGGED IN OR NOT
    const [Authed, setAuthed] = useState(null)

    useEffect(() => {
        const checkIfLoggedIn = async () => {
            try {
                await Auth.currentAuthenticatedUser()
                setAuthed(true)
            } catch (error) {
                setAuthed(false)
            }
        }

        checkIfLoggedIn()
    }, [])

    useEffect(() => {
        if (customization['Authed'] && customization['vervConnects'] === null) {
            dataLoad()
        }
        // TODO this needs review
    }, [customization['Authed']])

    useEffect(() => {
        dispatch({ type: SET_AUTHED, Authed })
    }, [dispatch, Authed])

    // TODO: move to a separate file
    async function dataLoad() {
        const id = LOADING('Fetching your devices...')
        const today = new Date().toLocaleDateString('en-CA', {
            timeZone: 'Europe/London',
        })

        try {
            dispatch(isLoading(true))
            const [
                vervConnects,
                smartClimateDevices,
                energyAllDevices,
                energyPerDevice,
                timesUsedPerDevice,
            ] = await Promise.all([
                getVervConnects(),
                getSmartClimateDevices(),
                getEnergyAllDevices(today),
                getEnergyPerDevice(today),
                getTimesUsedPerDevice(),
            ])

            // TODO keying device array by serial number(id) could be a good idea
            const energyDevices = vervConnects?.queryVervConnects?.items

            const allDevices = [
                ...(Array.isArray(energyDevices) ? energyDevices : []),
                ...(Array.isArray(smartClimateDevices)
                    ? smartClimateDevices
                    : []),
            ]

            let vervConnectsSerials = allDevices.map((item) => ({
                serial: item.serial,
                nickname: item.appliance.nickname,
                deviceType: item.device_type,
            }))

            // TODO - groups and roles shouldn't be included in this API response, it's implicit that those devices returned are viewable by the user
            // TODO - user perms should be defined by ACL tables not groups leading to lots of branching and nested code
            // ensure dupes are avoided with the same serial number belonging to different groups
            vervConnectsSerials = vervConnectsSerials.filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.serial === value.serial)
            )

            // Set the State

            dispatch({ type: SET_DEVICE_SERIALS, vervConnectsSerials })
            dispatch({ type: SET_ENERGY_ALL_DEVICES, energyAllDevices })
            dispatch({ type: SET_ENERGY_PER_DEVICE, energyPerDevice })
            dispatch({ type: SET_SMART_CLIMATE_DEVICES, smartClimateDevices })
            dispatch({ type: SET_USER_DEVICES, vervConnects: energyDevices })
            dispatch({ type: SET_TIMES_USED_PER_DEVICE, timesUsedPerDevice })

            dispatch(isLoading(false))

            SAVED(id, 'Devices Loaded')
        } catch (error) {
            // TODO Does this needs to be emptied when there is an error?
            dispatch({ type: SET_DEVICE_SERIALS, vervConnectsSerials: [] })
            dispatch({ type: SET_USER_DEVICES, vervData: [] })
            dispatch({ type: SET_ENERGY_ALL_DEVICES, energyAllDevices: [] })
            dispatch({ type: SET_ENERGY_PER_DEVICE, energyPerDevice: [] })
            dispatch({
                type: SET_TIMES_USED_PER_DEVICE,
                timesUsedPerDevice: [],
            })
            dispatch(isLoading(false))
            ERROR(id, 'NO DEVICES FOUND')
            console.error('Something went wrong!', error)
        }
    }

    // if serial changes update
    useEffect(() => {
        const { selectedSerial, vervConnectsSerials } = customization

        if (selectedSerial === null && vervConnectsSerials.length > 0) {
            const storedSelectedSerial = JSON.parse(
                localStorage.getItem('selectedSerial')
            )

            if (storedSelectedSerial) {
                // If selectedSerial is in the local storage, dispatch an action to set it in the Redux store, but don't update the localStorage
                dispatch(setSelectedSerial(storedSelectedSerial, true))
            } else {
                // If there is no selectedSerial in the local storage, set the first serial in the list as the selectedSerial
                const firstSerial = vervConnectsSerials[0]
                const selectedSerial = {
                    id: firstSerial.serial,
                    label: `${firstSerial.nickname} (${firstSerial.serial})`,
                }

                dispatch(setSelectedSerial(selectedSerial))
            }
        }
    }, [customization, dispatch])

    if (Authed === null) {
        return <CircularProgress />
    }

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ToastContainer
                position="bottom-right"
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
            />
        </>
    )
}

export default App
