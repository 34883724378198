import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSerial } from '../../../../../store/actions'

import Styles from '../../sidebar.module.scss'

// material-ui
import { useTheme } from '@mui/material/styles'
import { List, Typography, Autocomplete, TextField, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
const NavGroup = ({ item }) => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const customization = useSelector((state) => state.customization)
    const { vervConnects, vervConnectsSerials, selectedSerial } = customization

    const options = vervConnectsSerials.map((item) => {
        return { id: item.serial, label: `${item.nickname} (${item.serial})` }
    })

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />
            default:
                return (
                    <Typography
                        key={menu.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Menu Items Error
                    </Typography>
                )
        }
    })

    const handleDeviceChange = (event, value) => {
        dispatch(setSelectedSerial(value))
    }

    return (
        <>
            <List
                className={
                    item.title === 'devices' ? Styles.sticky : Styles.menuGroup
                }
                subheader={
                    item.title && (
                        <>
                            <Typography
                                variant="caption"
                                sx={{ ...theme.typography.menuCaption }}
                                display="block"
                                gutterBottom
                            >
                                {item.title}
                                {item.caption && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            ...theme.typography.subMenuCaption,
                                        }}
                                        display="block"
                                        gutterBottom
                                    >
                                        {item.caption}
                                    </Typography>
                                )}
                            </Typography>

                            {item.title === 'devices' ? (
                                options.length === 0 ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CircularProgress color="verv" />
                                    </Box>
                                ) : vervConnects?.length !== 0 ? (
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={options}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Devices"
                                            />
                                        )}
                                        style={{ marginBottom: 16 }}
                                        disableClearable={true}
                                        className={Styles.deviceAutocomplete}
                                        onChange={handleDeviceChange}
                                        value={selectedSerial}
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            return option.id === value.id
                                        }}
                                    />
                                ) : (
                                    <Alert severity="error">
                                        No Devices available.
                                    </Alert>
                                )
                            ) : (
                                <></>
                            )}
                        </>
                    )
                }
            >
                {items}
            </List>
        </>
    )
}

NavGroup.propTypes = {
    item: PropTypes.object,
}

export default NavGroup
